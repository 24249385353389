console.log('🥑 %cMade by Monk', 'background: #616a2e; color: #f4e9e2; padding: 5px 17px; border-radius: 3px;');
console.log(' %chttps://monk.com.au ', 'padding: 5px 13px;');

import './skip-link-focus-fix';
import './smoothscroll';
import './navigation';
import './scrollyclasses';
import './viewport-height';
import './fw-video';
import './sliders';
import './accordions';
import './fancybox';
import './gsapanims';

jQuery(function ($) {

	//page
	var $hamburger = $(".hamburger"),
		$site = $("body"),
		$menu = $(".main-navigation"),
		$menuitems = $(".menu-item"),
		$screenOverlay = $(".screen-overlay");

	/*
	-ˋˏ *.·:·.⟐.·:·.* ˎˊ-
	━━━ ⋅𖥔⋅ ━━✶━━ ⋅𖥔⋅ ━━━
	Mobile Menu
	━━━ ⋅𖥔⋅ ━━✶━━ ⋅𖥔⋅ ━━━
	-ˋˏ *.·:·.⟐.·:·.* ˎˊ-
	*/
	$hamburger.on("click", function () {
		$hamburger.toggleClass("is-active");
		$site.toggleClass("menu-open");
		// if ( $('body').hasClass('menu-open') ) {
		// 	$('#mobile-menu .label').text('Close')
		// } else {
		// 	$('#mobile-menu .label').text('Menu')
		// }
	});

	//close menu with an outside click (basically anywhere on .site-content)
	function closeMenu() {
		$site.removeClass("menu-open");
		$menu.removeClass("toggled");
		$menuitems.removeClass('toggled-on');
		$hamburger.removeClass("is-active");
	}

	$screenOverlay.on('click', closeMenu);

	$(document).on('keydown', function (e) {
		if (e.which == 27) {
			closeMenu();
		}
	});

	/*
	-ˋˏ *.·:·.⟐.·:·.* ˎˊ-
	━━━ ⋅𖥔⋅ ━━✶━━ ⋅𖥔⋅ ━━━
	Animate elements in on scroll
	━━━ ⋅𖥔⋅ ━━✶━━ ⋅𖥔⋅ ━━━
	-ˋˏ *.·:·.⟐.·:·.* ˎˊ-
	*/
	// $('.animate').on('inview', function (event, isInView) {
	// 	if (isInView) {
	// 		$(this).addClass('fadein');
	// 	}
	// });
	// AOS.init();

    var headerbox = document.getElementById("masthead");
    var bannerbox = document.getElementById("banner");
    const $rows = $('.header-row');
    const $baseDiff = $('.header-row.top').offset().top;
    var footerBlock = document.getElementById("colophon");

    $rows.each(function( i, row ) {

        if (typeof (row) != 'undefined' && row != null) {

            const $thisRow = $(row);

            // Get the height position of 'row' elements
            // and makes sure the offset height base from the top is 0 when reloading from middle of the page
            // this will make the rest of the 'row' elements have the height base in relation with the base offset height of 0
            const $rowTop = $thisRow.offset().top - $baseDiff;

            document.addEventListener("scroll", posCalc, false);
            if (window.innerWidth <= 600) {
                document.addEventListener("scroll", posCalc2, false);
            }

            function posCalc() {

                var heightDiff = bannerbox.getBoundingClientRect().bottom - 0; // Height from bottom of banner to top of document

                // If the height base of the banner is lower than the Y position of the element
                if ( heightDiff <= $rowTop && !$thisRow.hasClass('switch') ) {

                    $thisRow.addClass('switch');

                } else if ( heightDiff > $rowTop && $thisRow.hasClass('switch') ) {

                    $thisRow.removeClass('switch');

                }
                
            }

            function posCalc2() {

                if (window.innerWidth <= 600) {

                    if ( footerBlock.getBoundingClientRect().top - 40 <= $rowTop && !$thisRow.hasClass('foot-switch') ) {

                        $thisRow.addClass('foot-switch');

                    } else if ( footerBlock.getBoundingClientRect().top - 40 > $rowTop && $thisRow.hasClass('foot-switch') ) {

                        $thisRow.removeClass('foot-switch');

                    }

                }

            }

            posCalc();
            if (window.innerWidth <= 600) {
                posCalc2();
            }
        }

    });

    // Shorten Instagram text to insta for smaller screens
    function shortenInsta() {
        if (window.innerWidth <= 768) {
            $('.instagram').text("Insta");
        } else {
            $('.instagram').text("Instagram");
        }
    }

    shortenInsta();

    function scrollThis() {
        document.body.style.setProperty('--scroll', window.scrollY / (document.body.offsetHeight - window.innerHeight));

        // If bigger than phone size
        if (window.innerWidth >= 600) {

            // If footer shows up on screen, change to position absolute
            if ( footerBlock.getBoundingClientRect().bottom <= footerBlock.offsetHeight + window.innerHeight && !$("#masthead").hasClass('posabs') ) {
                $("#masthead").addClass('posabs');
                $("#masthead.posabs").css("bottom", footerBlock.offsetHeight);
            } else if ( footerBlock.getBoundingClientRect().bottom > footerBlock.offsetHeight + window.innerHeight && $("#masthead").hasClass('posabs') ) {
                $("#masthead").css("bottom", 0);
                $("#masthead").removeClass('posabs');
            }

        }

        // Add more responsive scrolling for mobile
        if ( document.body.style.getPropertyValue('--scroll') > 0 && window.innerWidth <= 600 ) {
            $site.addClass('mobile-scroll');
        } else if ( $site.hasClass('mobile-scroll') ) {
            $site.removeClass('mobile-scroll');
        }

        // If bigger than phone size
        if (window.innerWidth > 600) {

            // Squish fixed header when reaching the bottom of the screen
            if ($("#masthead").hasClass('posabs')) {
                $("#masthead.posabs").css("height", headerbox.getBoundingClientRect().bottom);
            } else {
                $("#masthead").css("height", "100vh");
            }
        }
    }

    document.addEventListener("scroll", scrollThis, false);

    window.addEventListener('resize', () => {

        // Re-calibrate index position for checking if footer is on the screen or not and act accordingly
        if ( $("#masthead").hasClass('posabs') ) {
            $("#masthead.posabs").css("bottom", footerBlock.offsetHeight);
        } else if ( !$("#masthead").hasClass('posabs') ) {
            $("#masthead").css("bottom", 0);
        }

        shortenInsta();

    }, false );

    $('#input_1_5, #input_1_5_2').on('keyup', function() {
        // Convert the entered text to lowercase
        var currentText = $(this).val();
        var lowercaseText = currentText.toLowerCase();
        
        // Set the lowercase text back to the input field
        $(this).val(lowercaseText); 
      });



    // const holder = document.querySelector("#banner");
    // const card = document.querySelector(".brand-logo .wp-block-image figure");
    // const motionMatchMedia = window.matchMedia("(prefers-reduced-motion)");
    // const THRESHOLD = 30;



    // function handleHover(e) {
    //     const { clientX, clientY, currentTarget } = e;
    //     const { clientWidth, clientHeight, offsetLeft, offsetTop } = currentTarget;

    //     const horizontal = (clientX - offsetLeft) / clientWidth;
    //     const vertical = (clientY - offsetTop) / clientHeight;

    //     const rotateX = (THRESHOLD / 2 - horizontal * THRESHOLD).toFixed(2);
    //     const rotateY = (vertical * THRESHOLD - THRESHOLD / 2).toFixed(2);

    //     card.style.transform = `perspective(${clientWidth}px) rotateX(${rotateY}deg) rotateY(${rotateX}deg) scale3d(1, 1, 1)`;
    // }
    
    // function resetStyles(e) {
    //     card.style.transform = `perspective(${e.currentTarget.clientWidth}px) rotateX(0deg) rotateY(0deg)`;
    //   }
    
    // if (!motionMatchMedia.matches) {
    //     holder.addEventListener("mousemove", handleHover);
    //     holder.addEventListener("mouseleave", resetStyles);
    // }

});